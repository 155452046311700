(function () {
  const app = {
    init() {
      const _this = app;
  
      svg4everybody();
  
  		// Переключатель - https://rainjeck.github.io/unitoggle/
  		_this.toggler(_this);
  
  		// Модальные окна - https://rainjeck.github.io/unimodal/
      _this.unimodal();
  
  		// Выключатель при клике мимо элемента - https://github.com/rainjeck/clickout
      const clickout = new clickOut({
        // className: '.js-clickout', // class name where outside click
        // activeClass: 'is-active', // class name for delete
  			after: elems => {
  				elems.forEach(el => {
  					// Подложка при открытом меню
  					if (el.id == 'main-menu') {
  						document.documentElement.classList.remove('is-main-menu-open');
  					}
  				});
        } // after click
      });
  
      _this.clipboardCopy();
  
  		_this.yaMap();
    },
  
    toggler(_this) {
      const toggler = new Unitoggle({
  			onOpen: tab => {
  				if (tab.id == 'main-menu') {
  					_this.blockScroll(true);
  					document.documentElement.classList.add('is-main-menu-open');
  				}
        }, // After open callback
        onClose: tab => {
  				_this.blockScroll(false);
        } // After close callback
      });
    },
  
  	blockScroll(status = false) {
  		// Блокиратор скролла окна
      if (!status) {
  			document.documentElement.classList.remove('is-no-scroll');
  			document.documentElement.classList.remove('is-main-menu-open');
        return;
      }
  
  		document.documentElement.classList.add('is-no-scroll');
    },
  
    unimodal() {
      const modal = new Unimodal({
        scrollWindow: false,
        onOpen: ( modal, button ) => {
        },
        onClose: modal => {
        }
      });
    },
  
  	clipboardCopy() {
  		// Копирование в буфер по клику
      const elems = document.querySelectorAll('.js-clipboard-copy');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        const el_input = el.querySelector('.js-input');
        const el_btn = el.querySelector('.js-btn');
  
        el_btn.addEventListener('click', e => {
          const val = el_input.value;
          navigator.clipboard.writeText(val);
          el.classList.add('is-active');
  
          setTimeout(() => {
            el.classList.remove('is-active');
          }, 2000);
        });
      });
  	},
  
  	yaMap() {
  		// Карта Яндекс
  		const el = document.querySelector('#ya-map');
  
  		if (!el) return;
  
  		ymaps.ready(() => {
        const coords = JSON.parse(el.getAttribute('data-coords'));
  			const zoom = parseInt(el.getAttribute('data-zoom'));
  
  			const icon_size = [32, 42];
  			const icon_pos = [-16, -42];
  
        const myMap = new ymaps.Map(el.id, {
          center: coords,
          zoom: zoom,
          controls: []
        });
  
        // Отключаем часть включенных по умолчанию поведений:
  			myMap.behaviors.disable([
  				// 'drag',
  				// 'scrollZoom',
  				'dblClickZoom',
  				'leftMouseButtonMagnifier',
  				'rightMouseButtonMagnifier',
  				// 'multiTouch'
  			]);
  
  			const myPlacemark = new ymaps.Placemark(coords, {
  
  			}, {
          iconLayout: 'default#image',
          iconImageHref: 'assets/icons/location.svg',
          iconImageSize: icon_size,
          iconImageOffset: icon_pos
        });
  
  			myMap.geoObjects.add(myPlacemark);
  
  			// Открываем балун на карте (без привязки к геообъекту).
  			myMap.balloon.open(coords, el.querySelector('.js-balloon').innerHTML, {
  				closeButton: false, // Не показываем кнопку закрытия.
  				offset: [0,-42] // Смещение
      	});
  
  			// Кнопки Zoom
  			const btn_zoom_in = el.querySelector('.js-zoom-in');
  			const btn_zoom_out = el.querySelector('.js-zoom-out');
  
  			btn_zoom_in.addEventListener('click', e => {
  				myMap.setZoom(myMap.getZoom() + 1, { checkZoomRange: true });
  			});
  
  			btn_zoom_out.addEventListener('click', e => {
  				myMap.setZoom(myMap.getZoom() - 1, { checkZoomRange: true });
  			});
      });
  	}
  };
  
  const form = {
  	init() {
  		// Валидация форм - https://github.com/cferdinandi/bouncer
  		// Автокомплит - https://github.com/TarekRaafat/autoComplete.js
      const _this = form;
  
  		_this.inputAutocomplete();
  		_this.formFiles();
  
      _this.setup(_this);
      _this.validation();
      _this.sending();
  	},
  
  	inputAutocomplete() {
  		const el = document.querySelector('#input-search');
  
  		if (!el) return;
  
  		const autoCompleteJS = new autoComplete({
  			selector: '#input-search',
  			wrapper: false,
  			threshold: 2,
  			debounce: 300,
  			resultsList: {
  				class: 'autocompleteResult',
  				maxResults: 15,
  				tabSelect: true,
  				destination: "#input-search",
  				noResults: true
  			},
  			resultItem: {
  				class: "autocompleteResult-item",
  				highlight: true,
  			},
  			data: {
  				// keys: ['name'],
  				src: async (query) => {
  					try {
  						// const source = await fetch(`https://www.api.com/${query}`);
  						const source = await fetch('assets/search-countries.json');
  						const data = await source.json();
  						const result = data.map(item => {
  							return item.name;
  						});
  						return result;
  					} catch (error) {
  						return error;
  					}
  				},
          cache: false,
  			}
  		});
  
  		autoCompleteJS.input.addEventListener("selection", function (event) {
  			const feedback = event.detail;
  			autoCompleteJS.input.blur();
  			autoCompleteJS.input.value = feedback.selection.value;
  		});
  
  	},
  
  	formFiles() {
  		const elems = document.querySelectorAll('[data-input-file]');
  
  		if (!elems.length) return;
  
  		window.AppFileList = [];
  
  		const checkMimeType = type => {
  			const mimetypes = [
  				'application/msword',
  				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  				'application/pdf',
  				'image/jpeg',
  				'image/jpeg',
  				'image/png'
  			];
  
  			if (mimetypes.includes(type)) {
  				return true;
  			}
  			return false;
  		};
  
  		const showFiles = form => {
  			let html = '';
  			const display = form.querySelector('.js-file-list');
  
  			if (!window.AppFileList.length) {
  				display.innerHTML = html;
  			}
  
  			const template = form.querySelector('.js-file-template').innerHTML;
  
  			window.AppFileList.forEach((file, idx) => {
  				let file_template = template.replace('{filename}', file.name);
  				file_template = file_template.replace('{filekey}', idx);
  				html += file_template;
  				display.innerHTML = html;
  			});
  		};
  
  		const delFile = (idx,form) => {
  
  		};
  
  		elems.forEach(el => {
  			const elem_display_filelist = el.querySelector('.js-file-list');
  
  			const input = el.querySelector('input[type="file"]');
  
  			input.addEventListener('change', e => {
  				const filelist = e.target.files;
  
  				for (let i = 0; i < filelist.length; i++) {
  					const allow_mimetype = checkMimeType(filelist[i].type);
  					if (allow_mimetype) {
  						// Добавляем файл в массив загрузки
  						window.AppFileList.push(filelist[i]);
  					}
  				}
  
  				// Очищаем поле, если нет файлов для загрузки
  				if (!window.AppFileList.length) {
  					e.currentTarget.value = null;
  				}
  
  				showFiles(el);
  			});
  
  			elem_display_filelist.addEventListener('click', e => {
  				if (!e.target.closest('button[data-key]')) return;
  
  				const btn = e.target.closest('button[data-key]');
  				const form = btn.closest('form');
  
  				const idx = btn.getAttribute('data-key');
  
  				window.AppFileList.splice(parseInt(idx), 1);
  
  				showFiles(form);
  
  				// Очищаем поле, если нет файлов для загрузки
  				if (!window.AppFileList.length) {
  					e.currentTarget.value = null;
  				}
  			});
  		});
  	},
  
    setup() {
      this.bouncerSettings = {
        messageAfterField: true,
        messages: {
          missingValue: {
            checkbox: 'Обязательное поле',
            radio: 'Выберите значение',
            select: 'Выберите значение',
            'select-multiple': 'Выберите значение',
            default: 'Обязательное поле'
          },
          patternMismatch: {
            email: 'Не верный формат e-mail',
            default: 'Проверьте формат значения'
          }
        },
        disableSubmit: true
      }
    },
  
    validation() {
      if (typeof Bouncer === 'undefined') return;
  
      window.AppBouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);
  
      document.addEventListener('bouncerRemoveError', e => {
        const field = e.target;
        field.classList.add('is-valid');
      }, false);
  
      document.addEventListener('bouncerShowError', e => {
        const field = e.target;
        field.classList.remove('is-valid');
      }, false);
    },
  
    sending() {
      document.addEventListener('bouncerFormValid', e => {
        const form = e.target;
        const type = form.dataset.type;
  
        if (form.hasAttribute('method')) {
          form.submit();
          return;
        }
  
        const btn = form.querySelector('[type="submit"]');
  
        const url = form.getAttribute('data-url');
  
        const fd = new FormData(form);
  
        form.classList.add('is-process');
        btn.setAttribute('disabled', true);
  
        console.log('Здесь можно обработать все формы по их ID');
  
        // Demo Sending
        setTimeout(() => {
          form.classList.remove('is-process');
          btn.removeAttribute('disabled');
          form.reset();
        }, 1000);
        return;
  
        fetch(url, {
          method: 'POST',
          body: fd
        }).then(response => response.json()).then(res => {
          console.log(res);
  
          form.classList.remove('is-process');
          btn.removeAttribute('disabled');
          form.reset();
  
          if (res.data.url) {
            window.location.assign(res.data.url);
          }
        });
      }, false);
    }
  };
  
  const slider = {
    init() {
  		// Слайдер - https://swiperjs.com
  		const _this = slider;
  
  		_this.slHero();
    },
  
    slHero() {
  		const el = document.querySelector('#sl-hero');
  
      if (!el) return;
  
      const sl = new Swiper(el, {
        slidesPerView: 'auto',
        slideNextClass: 'is-next',
        slidePrevClass: 'is-prev',
        slideActiveClass: 'is-active',
        pagination: {
          el: el.querySelector('.js-pagination'),
          type: 'bullets',
          modifierClass: '',
          clickable: true,
          bulletActiveClass: 'is-active',
          bulletClass: 'bullet',
          currentClass: 'is-current'
  			},
  			on: {
  				slideChangeTransitionStart: sw => {
  					const el_logo = document.querySelector('#header-logo');
  
  					const slide_active = sw.slides[sw.realIndex];
  					if (slide_active.classList.contains('is-pic')) {
  						el_logo.classList.add('is-active');
  					} else {
  						el_logo.classList.remove('is-active');
  					}
  				}
  			}
      });
    }
  };
  
  const graph = {
  	init() {
  		// Chart.js - https://www.chartjs.org
  		const _this = graph;
  
  		if (typeof Chart == 'function') {
  			window.AppChart = [];
  
  			Chart.defaults.font = {
  				size: 10,
  				family: "'DM Sans', sans-serif",
  				weight: 'bold'
  			};
  
  			_this.graphVertical();
  			_this.graphLines();
  		}
  	},
  
  	graphVertical() {
  		// График со столбцами
  		const elems = document.querySelectorAll('.js-graph-vertical');
  
  		if (!elems.length) return;
  
  		elems.forEach(el => {
  			const ctx = el.getContext('2d');
  			const title = el.getAttribute('data-title');
  			const is_post = (el.classList.contains('is-post')) ? true : false;
  
  			// Данные
  			const data = {
  				labels: ['32,4', '33,4', '32,4', '34,4', '32,4'],
  				datasets: [
  					{
  						label: 'Кандидат 1',
  						data: [10,20,30,10, 20],
  						backgroundColor: '#775FFC'
  					},
  					{
  						label: 'Кандидат 2',
  						data: [20, 30, 40, 20, 30],
  						backgroundColor: '#27A9FB'
  					},
  					{
  						label: 'Кандидат 3',
  						data: [30, 40, 50, 30, 40],
  						backgroundColor: '#E9F3FF'
  					},
  				],
  			};
  
  			const config = {
  				type: 'bar',
  				data: data,
  				options: {
  					animation: {duration: 2000, delay: 0},
  					barThickness: 18,
  					borderRadius: 30,
  					responsive: true,
  					interaction: {
  						mode: 'index',
  						intersect: false,
  					},
  					plugins: {
  						legend: {
  							display: false
  						},
  						tooltip: {
  							titleFont: {
  								weight: 400
  							}
  						},
  						title: {
  							display: (title) ? true : false,
  							text: title,
  							align: 'start',
  							padding: {bottom: 25},
  							color: '#1B2559',
  							font: {
  								family: "'HelveticaNeueCyr', sans-serif",
  								weight: 500,
  								size: (window.innerWidth > 767) ? 20 : 16,
  							}
  						}
          	},
  					scales: {
  						x: {
  							grid: {
  								display: false,
  								drawBorder: false,
  							},
  							ticks: {
  								font: {
  									size: 13,
  									family: "'Montserrat', sans-serif",
  									weight: (!is_post) ? 'bold' : 'normal'
  								}
  							},
  							stacked: true,
  						},
  						y: {
  							display: false,
  							grid: {
  								display: false,
  								drawBorder: false
  							},
  							stacked: true
  						}
  					},
  				}
  			};
  
  			window.AppChart[el.id] = {
  				id: el.id,
  				el: el,
  				ctx: ctx,
  				config: config
  			};
  		});
  	},
  
  	graphLines() {
  		// График линиями
  		const elems = document.querySelectorAll('.js-graph-lines');
  
  		if (!elems.length) return;
  
  		elems.forEach(el => {
  			const ctx = el.getContext('2d');
  
  			// Данные
  			const data = {
  				labels: ['SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB'],
  				datasets: [
  					{
  						label: 'Кандидат 1',
  						data: [20, 30, 40, 35, 30, 40],
  						backgroundColor: '#4318FF',
  						borderWidth: 4,
  						borderColor: '#4318FF',
  						fill: false,
  						cubicInterpolationMode: 'monotone',
  						pointBorderWidth: 3,
  						pointHoverBorderColor: '#4318FF',
  						pointHoverBorderWidth: 2,
  						hoverBackgroundColor: '#ffffff'
  					},
  					{
  						label: 'Кандидат 2',
  						data: [10, 20, 30, 25, 20, 25],
  						backgroundColor: '#6AD2FF',
  						borderWidth: 4,
  						borderColor: '#6AD2FF',
  						fill: false,
  						cubicInterpolationMode: 'monotone',
  						pointBorderWidth: 3,
  						pointHoverBorderColor: '#6AD2FF',
  						pointHoverBorderWidth: 2,
  						hoverBackgroundColor: '#ffffff'
  					},
  				],
  			};
  
  			const config = {
  				type: 'line',
  				data: data,
  				options: {
  					animation: {duration: 2000, delay: 0},
  					interaction: {
  						mode: 'index',
  						intersect: false,
  					},
  					plugins: {
  						legend: {
  							display: false,
  						},
  					},
  					responsive: true,
  					scales: {
  						x: {
  							display: true,
  							grid: {
  								display: false,
  								borderWidth: 0
  							},
  							ticks: {
  								color: '#A3AED0',
  								font: {
  									size: 10,
  									family: "'DM Sans', sans-serif"
  								}
  							}
  						},
  						y: {
  							display: false,
  							grid: {
  								display: false
  							}
  						}
  					},
  				}
  			};
  
  			window.AppChart[el.id] = {
  				el: el,
  				id: el.id,
  				ctx: ctx,
  				config: config
  			};
  		});
  	}
  };
  
  const animation = {
  	init() {
  		// GSAP - https://greensock.com/gsap/
  		const _this = animation;
  
  		_this.animationCount();
  		_this.animationGraphHorizontal();
  		_this.animationChart();
  	},
  
  	animationCount() {
  		const elems = document.querySelectorAll('.js-animation-count');
  
  		if (!elems.length) return;
  
  		elems.forEach(el => {
  			let value = 0;
  
  			value = el.getAttribute('data-value');
  
  			const countUp = new CountUp(el, value, { enableScrollSpy: true });
  		});
  	},
  
  	animationGraphHorizontal() {
  		const elems = document.querySelectorAll('.js-gsap-graph-horizontal');
  
  		if (!elems.length) return;
  
  		elems.forEach(el => {
  			const scale = el.querySelectorAll('.js-scale');
  
  			gsap.to(scale, {
  				scrollTrigger: {
  					trigger: el,
  					start: 'top 90%',
  					// markers: true
  				},
  				duration: 2,
  				width: function (index, target) {
  					const val = target.getAttribute("data-value");
  					return val + '%';
  				}
  			});
  		});
  	},
  
  	animationChart() {
  		const elems = document.querySelectorAll('.js-gsap-canvas');
  
  		if (!elems.length) return;
  
  		elems.forEach(el => {
  			gsap.to(el, {
  				scrollTrigger: {
  					trigger: el,
  					start: 'top 90%',
  					// markers: true
  				},
  				duration: 0.2,
  				onComplete() {
  					const myChart = new Chart(window.AppChart[el.id].ctx, window.AppChart[el.id].config);
  				},
  				opacity: 1
  			});
  		});
  	}
  };
  

  document.addEventListener('DOMContentLoaded', () => {
    app.init();
    form.init();
		slider.init();
		graph.init();
		animation.init();
  });
}());
